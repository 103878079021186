import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const AboutPage = ({ active = `about` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | About Us" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-mypurple"></div>
    <div className="max-w-[1200px] mx-auto -mt-44">
      <p className="text-white text-3xl lg:text-4xl pb-4 pl-6 font-tbold">
        Research &amp; Publications
      </p>
      <StaticImage
        src="../images/image-books.jpg"
        alt="Decorative image of a bookcase"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8 text-center mx-auto">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">Our Work</h3>
        <h2 className="text-4xl md:text-5xl mb-12">
          Well-Being, Leadership, Performance and Sustainability
        </h2>
        <p className="text-xl md:text-2xl mb-6 text-gray-800">
          The dimensions of well-being are interrelated, and by solving for the
          whole rather than a part, employers can maximize performance and make
          their organizations great places to work. Research shows consistently
          that employee well-being includes advancement, managerial and physical
          workplace considerations, as well as people’s physical and
          psychological health.
        </p>
      </div>
    </section>

    <section className="py-12 max-w-[1400px] mx-auto">
      <div className="flex flex-col md:flex-row">
        <div className="md:max-w-sm px-8">
          <h3 className="text-myred text-3xl mb-8">
            Workers’ Voice, HRM Practice, and Leadership in the Public Sector:
            Multidimensional Well-Being at Work
          </h3>
          <h2 className="text-2xl mb-4 md:mb-8 font-bold">
            Author: Dr. Nicole Cvenkel
          </h2>
          <h2 className="text-2xl mb-4 md:mb-8">
            Published (2020) by Routledge Taylor and Francis Group.
          </h2>
        </div>
        <div className="border-l-2 border-gray-300 pl-6">
          <div className="max-w-[1400px] overflow-hidden h-full">
            <StaticImage
              src="../images/book1.jpg"
              alt="Decorative image of book"
              objectPosition="0 0"
              className="mx-20 lg:m-0 max-w-sm lg:max-w-md"
            />
          </div>
        </div>
      </div>
      <p className="text-lg md:text-xl mb-6 text-gray-800 mt-8 px-6">
        Much is talked and written about well-being in the workplace, but many
        wonder whether 'putting people first' is just a facade and that were it
        not for employment legislation, union representation and the high
        profile of human rights issues, employers would regard employees as a
        necessary burdensome financial evil, as in days gone by. Some scholarly
        research has focused on the reactions of employees to the quality of
        working life and well-being at work and much of this suggests high
        levels of dissatisfaction, disaffection, and disengagement. In Workers'
        Voice, HRM Practice, and Leadership in the Public Sector:
        Multidimensional Well-Being at Work, Nicole Cvenkel avers that whilst it
        is known that public sector employees are even less satisfied than those
        in the private sector, there has been very little research into the
        effects of working life experiences on employee well-being in public
        sector organizations. There is even some doubt about whether a
        well-being philosophy that can be applied in the private sector can
        readily be extended to the public sector.
      </p>
      <p className="text-lg md:text-xl mb-6 text-gray-800 mt-8 px-6">
        The push towards New Public Management (NPM) means organizations
        continue to undergo significant reform processes around efficiency,
        costs, and public service delivery. All these changes place additional
        demands on public sector employees who are at times also subject to
        intensive scrutiny by stakeholder groups, who may regard the recourse to
        well-being initiatives as a poor use of public funds. The author has
        researched the UK local government sector and that is the setting for
        the debate in this book, about whether and how an employee well-being
        ideology can be successfully promoted and maintained in an NPM
        environment, given continuous reform and expenditure reduction. In a
        local government case organization, the author has researched, limited
        resources, reduction in budgets, redundancies, increased workloads, lack
        of trust, and the existence of a 'controlled' working environment were
        all found to be central to a climate of bullying and unfairness.
        Although the organization was committed to the adoption of HRM 'best
        practices’ and initiatives geared towards promoting employees’
        well-being, employees still believed they were being bullied and treated
        unfairly. It was found that different perspectives on the psychological
        contract, fairness, and bullying at work were highlighted by managerial
        and non-managerial employees. The author's conclusions contribute to a
        clearer understanding than hitherto of workers' voice in relation to
        work, leader-member exchanges, and well-being in the public sector and
        she offers a model depicting employees' understanding of what their
        quality of working life, line manager’s leadership and well-being should
        be, that might be used by organizational leaders, researchers,
        policymakers, Human Resources managers and other practitioners and
        consultants, to move towards a more holistic, multidimensional,
        well-being at work paradigm.
      </p>
    </section>

    <section className="py-12 max-w-[1400px] mx-auto bg-slate-50 mt-8">
      <div className="flex flex-col md:flex-row">
        <div className="md:max-w-sm px-8">
          <h3 className="text-myred text-3xl mb-8">
            Well-Being in the Workplace: Governance and Sustainability Insights
            to Promote Workplace Health
          </h3>
          <h2 className="text-2xl mb-4 md:mb-8 font-bold">
            Author: Dr. Nicole Cvenkel
          </h2>
          <h2 className="text-2xl mb-4 md:mb-8">
            Published (2020) by Springer Publishers
          </h2>
        </div>
        <div className="border-l-2 border-gray-300 pl-6">
          <div className="max-w-[1400px] overflow-hidden h-full">
            <StaticImage
              src="../images/book2.jpg"
              alt="Decorative image of book"
              objectPosition="0 0"
              className="mx-20 lg:m-0 max-w-sm lg:max-w-md"
            />
          </div>
        </div>
      </div>
      <p className="text-lg md:text-xl mb-6 text-gray-800 mt-8 px-6">
        This book is intended for human resources management academics,
        researchers, students, organizational leaders and managers, HR
        Practitioners, and those responsible for helping support employees in
        the 21st-century workplace. It offers a path forward to create an
        environment that will not only build a healthier workplace by providing
        appropriate and effective well-being interventions but also offers
        solutions to manage multi-generational and ‘holistic’ employees within
        the employment relationship.
      </p>
      <p className="text-lg md:text-xl mb-6 text-gray-800 mt-8 px-6">
        The book describes the factors that promote healthy and WELL
        organizations and introduces concepts and strategies to reduce workplace
        stress and mental health issues and improve workplace well-being toward
        sustained organizational success. Employers that embrace the corporate
        responsibility of promoting the health and well-being of
        multi-generational, holistic employees will reap cost savings, employee
        engagement, and productivity advantages, as well as a healthier and more
        productive workforce.
      </p>
    </section>

    <section className="py-12 max-w-[1400px] mx-auto">
      <div className="flex flex-col md:flex-row">
        <div className="md:max-w-sm px-8">
          <h3 className="text-myred text-3xl mb-8">
            Promoting Healthy Workplaces: Well-Being Insights for Leadership,
            Engagement, and Productivity
          </h3>
          <h2 className="text-2xl mb-4 md:mb-8 font-bold">
            Author: Dr. Nicole Cvenkel
          </h2>
          <h2 className="text-2xl mb-4 md:mb-8">
            Published: 2019 (Author House Publishers)
          </h2>
        </div>
        <div className="border-l-2 border-gray-300 pl-6">
          <div className="max-w-[1400px] overflow-hidden h-full">
            <StaticImage
              src="../images/book3.jpg"
              alt="Decorative image of book"
              objectPosition="0 0"
              className="mx-20 lg:m-0 max-w-sm lg:max-w-md"
            />
          </div>
        </div>
      </div>
      <p className="text-lg md:text-xl mb-6 text-gray-800 mt-8 px-6">
        The health and well-being of people of working age are of fundamental
        importance to the future of work and organizational productivity
        globally. Growing evidence suggests that employee well-being at work can
        help improve physical and mental health, reduce health inequalities, and
        offer improved opportunities for engagement, wellness at work, and
        productivity. The debate about the impact of working life on employee
        well-being has intensified.
      </p>
      <p className="text-lg md:text-xl mb-6 text-gray-800 mt-8 px-6">
        Whilst the issue of employee well-being at work has reached a new level
        of importance in the minds of policymakers, managers, and employers
        there is still little evidence that attention has been paid to the
        worker’s voice in their evaluation of HRM practices, line management
        leadership, the quality of working life and well-being at work in
        organizations. Research within these areas remains relatively untapped.
        Furthermore, understanding employees’ expectations of the psycho-social
        factors affecting the employment relationship and employee well-being at
        work are all lacking in the evidence base. This book seeks to contribute
        to the debate in these areas.
      </p>
    </section>

    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/motivation.jpg"
          alt="Decorative image depicting the word motivation"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default AboutPage
